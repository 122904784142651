import BasicLayout from '@/layouts/basic-layout'

const prefix = 'contract-management-'
export default {
  path: '/contract-management',
  name: 'contract-management',
  redirect: {name: `${prefix}signing`},
  component: BasicLayout,
  children: [
    {
      path: 'signing',
      name: `${prefix}signing`,
      meta: {title: '签约列表', cache: true},
      component: () => import('@/pages/rent/contract-management/signing')
    },
    {
      path: 'signing/init/:id?',
      name: `${prefix}signing-init`,
      meta: {title: '新增合同', cache: true},
      component: () => import('@/pages/rent/contract-management/signing/operation/init')
    },
    {
      path: 'signing/qyzzinit/:id?',
      name: `${prefix}signing-qyzzinit`,
      meta: {title: '新增合同(企业转租)', cache: true},
      component: () => import('@/pages/rent/contract-management/signing/operation/qyzzinit')
    },
    {
      path: 'signing/contract-sp/:id',
      name: `${prefix}signing-contract-sp`,
      meta: {title: '合同审批'},
      component: () => import('@/pages/rent/contract-management/signing/operation/details/contract-sp')
    },
    {
      path: 'signing/cancel-contract-sp/:id',
      name: `${prefix}signing-cancel-contract-sp`,
      meta: {title: '合同取消审批'},
      component: () => import('@/pages/rent/contract-management/signing/operation/details/cancel-contract-sp')
    },
    {
      path: 'signing/stay-in/:id',
      name: `${prefix}signing-stay-in`,
      meta: {title: '租客入住', cache: true},
      component: () => import('@/pages/rent/contract-management/signing/operation/stay-in')
    },
    {
      path: 'exit',
      name: `${prefix}exit`,
      meta: {title: '退租列表', cache: true},
      component: () => import('@/pages/rent/contract-management/exit')
    },
    {
      path: 'exit/check-out/:id',
      name: `${prefix}exit-check-out`,
      meta: {
        title: '退房申请',
        cache: true
      },
      component: () => import('@/pages/rent/contract-management/exit/check-out')
    },
    {
      path: 'exit/settlement/:id',
      name: `${prefix}exit-settlement`,
      meta: {title: '退租结算申请', cache: true},
      component: () => import('@/pages/rent/contract-management/exit/settlement')
    },
    {
      path: 'exit/settlement/details-sp-sk/:id',
      name: `${prefix}exit-settlement-details-sp-sk`,
      meta: {title: '退租结算申请详情审批'},
      component: () => import('@/pages/rent/contract-management/exit/settlement/details-sp-sk')
    },
    {
      path: 'exit/settlement/details-sp-fk/:id',
      name: `${prefix}exit-settlement-details-sp-fk`,
      meta: {title: '退租结算申请详情审批'},
      component: () => import('@/pages/rent/contract-management/exit/settlement/details-sp-fk')
    },
    {
      path: 'exit/settlement/details/:id',
      name: `${prefix}exit-settlement-details`,
      meta: {title: '退租结算申请详情'},
      component: () => import('@/pages/rent/contract-management/exit/settlement/details')
    },
    {
      path: 'exit/details/:id',
      name: `${prefix}exit-details`,
      meta: {title: '退租详情'},
      component: () => import('@/pages/rent/contract-management/exit/check-out/details.vue')
    },
    {
      path: 'relet',
      name: `${prefix}relet`,
      meta: {title: '续租', cache: true},
      component: () => import('@/pages/rent/contract-management/relet')
    },
    {
      path: 'relet/init/:id',
      name: `${prefix}relet-init`,
      meta: {title: '续租申请', cache: true},
      component: () => import('@/pages/rent/contract-management/relet/operation/init')
    },
    {
      path: 'change',
      name: `${prefix}change`,
      meta: {title: '换房列表', cache: true},

      component: () => import('@/pages/rent/contract-management/change')
    },
    {
      path: 'change/check-out/:id',
      name: `${prefix}change-check-out`,
      meta: {title: '验房'},
      component: () => import('@/pages/rent/contract-management/change/check-out')
    },
    {
      path: 'change/contract/contract-confirm/:id',
      name: `${prefix}change-contract-confirm`,
      meta: {title: '确认合同'},
      component: () => import('@/pages/rent/contract-management/change/contract/contract-confirm')
    },
    {
      path: 'change/contract/contract-confirm-detail/:id',
      name: `${prefix}change-contract-confirm-detail`,
      meta: {title: '合同详情'},
      component: () => import('@/pages/rent/contract-management/change/contract/contract-confirm-detail')
    },
    {
      path: 'change/check-out/details/:id',
      name: `${prefix}change-check-out-details`,
      meta: {title: '验房详情'},
      component: () => import('@/pages/rent/contract-management/change/check-out/details')
    },
    {
      path: 'change/settlement/:id',
      name: `${prefix}change-settlement`,
      meta: {title: '换房结算'},
      component: () => import('@/pages/rent/contract-management/change/settlement')
    },
    {
      path: 'change/settlement/details/:id',
      name: `${prefix}change-settlement-details`,
      meta: {title: '换房结算详情'},
      component: () => import('@/pages/rent/contract-management/change/settlement/details')
    },
    {
      path: 'change/details/:id',
      name: `${prefix}change-details`,
      meta: {title: '换房详情'},
      component: () => import('@/pages/rent/contract-management/change/details')
    }
  ]
}
