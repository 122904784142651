import Qs from 'qs'
import { get, pick, isArray, isObject } from 'lodash'
import { saveAs } from 'file-saver'
import cryptoJS from 'crypto-js'
import dayjs from 'dayjs'

import storage from '@/libs/vendors/storage'
import { router } from '@/router'
import { treeToFlat } from '@/libs/system'
import Setting, { sso, SECRET_IV, SECRET_KEY } from '@/setting'

/**
 * sleep
 * @param {number=} second 等待时间。单位（秒）
 * @return {promise}
 */
export const sleep = (second = 0) => new Promise(resolve => setTimeout(() => resolve(), second * 1000))

/**
 * 是否微信浏览器
 * @return {boolean}
 */
export const isWecaht = () => /micromessenger/i.test(navigator.userAgent)

/**
 * 是否企业微信浏览器
 * @return {boolean}
 */
export const isWxwork = () => /wxwork/i.test(navigator.userAgent)

/**
 * 是否正确邮箱
 * @param {string} email
 * @return {boolean}
 */
export const isEmail = email => /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email)

/**
 * 跳转统一登录
 */
export const jumpSSOLogin = ({ to, logout = false }) => {

  const currentRoute = router.resolve({ name: 'dashboard-console' })
  const redirect = router.resolve({
    name: 'redirect',
    params: { route: currentRoute.route.fullPath.substring(1) }
  })

  // 清除浏览器缓存
  storage.clear()
  storage.session.clear()
  location.href = `${sso}?redirect=${encodeURIComponent(`${location.href.split('#')[0]}${redirect.href}`)}${logout && get(to, 'query.system') !== 'jump' ? `&logout=${logout}` : ''}`
}

/**
 * 路由钩子动态判断是否有权限访问页面
 * @param {import('vue-router/types/router').Route} route
 * @param {array} menuList
 * @return {boolean}
 */
export const permissionGuard = (route, menuList = []) => {
  if (!isArray(menuList)) return false
  return treeToFlat(menuList).filter(item => item.path === route.fullPath).length > 0
}

/**
 * 转换菜单
 * @param array 菜单数组
 * @param parent 父级菜单
 * @return {array}
 */
export const transferMenusList = (array, parent = {}) => {
  if (!isArray(array)) return []
  return array
    .filter(item => [1, 2].includes(item.type))
    .map(item => {
      if (!isArray(item.children)) item.children = []
      item.path = item.code
      item.title = item.name
      item.header = item.parentId != 0 ? get(parent, 'header', '') : 'home'
      item.openNames = [...get(parent, 'openNames', []), item.code]
      item.activePath = item.hidden === 0 ? item.path : get(parent, 'path', '')

      item.children = transferMenusList(item.children, item)
      return item
    })
}
/**
 * 过滤隐藏菜单
 * @param array 菜单数组
 * @return {array}
 */
export const filterHiddenMenusList = (array) => {
  if (!isArray(array)) return []
  return array
    .filter(item => item.hidden === 0)
    .map(item => {
      if (isArray(item.children)) item.children = filterHiddenMenusList(item.children)
      return item
    })
}

/**
 * 下载文件
 * @param {AxiosResponse} response
 * @param {string} filename 自定义文件名
 * @param {string} suffix 自定义后缀
 */
export const download = (response, filename = '', suffix = 'xlsx') => {
  const regex = /filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/i
  const blob = new Blob([response.data], { type: get(response.headers, 'content-type') })
  const fileName = get(get(response.headers, 'content-disposition', '').match(regex), 1)

  saveAs(
    blob,
    filename
      ? `${filename}.${suffix}`
      : fileName
        ? decodeURIComponent(fileName)
        : `${router.currentRoute.meta.title}.${suffix}`
  )
}

/**
 * 判断是否为图片格式
 * @param path
 * @return {boolean}
 */
export const isImage = path => /\.(png|jpg|gif|jpeg|webp)$/.test(path)
export const fileTypeTransfer = fileType => {
  return {
    1: 'image/image',
    2: 'video/video',
    3: 'audio/audio',
    4: 'file/file',
  }[fileType]
}
export const transferFileType = fileType => {
  switch (fileType.split('/')[0]) {
    case 'image':
      return 1
    case 'video':
      return 2
    case 'audio':
      return 3
    default:
      // file
      return 4
  }
}

/**
 * 加密
 * @param {object | string} data
 * @return {string}
 */
export const encrypt = (data) => {
  //加密
  if (isObject(data)) {
    try {
      data = JSON.stringify(data)
    } catch (e) {
      throw new Error('encrypt error' + e)
    }
  }
  const dataHex = cryptoJS.enc.Utf8.parse(data)
  const encrypted = cryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString()
}

/**
 * 解密
 * @param {string} data
 * @return {string}
 */
export const decrypt = (data) => {
  const encryptedHexStr = cryptoJS.enc.Hex.parse(data)
  const str = cryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = cryptoJS.AES.decrypt(str, SECRET_KEY, {
    iv: SECRET_IV,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7,
  })
  const decryptedStr = decrypt.toString(cryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

/**
 * 格式化日期
 * @param value
 * @param format
 * @param customParseFormat
 * @return {string}
 */
export const formatDate = (value, format = 'YYYY-MM-DD', customParseFormat = '') => {
  if (!value) return ''
  if (!customParseFormat) return dayjs(value).format(format)
  return dayjs(value, customParseFormat).format(format)
}

/**
 * 跳转对应平台
 * @param {string|object} data 携带参数
 * @param {string=} redirect 重定向地址
 */
export function jumpPlatform(data, redirect = '') {
  data.id = data.userId
  location.href = `${redirect}${redirect.includes('?') ? '&' : '?'}${Qs.stringify(pick(data, ['id', 'userCode', 'userType', 'accessToken']))}`
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
export const title = ({ title, count }) => {
  const fullTitle = `${title ? `${title} - ` : ''}${Setting.titleSuffix}`

  window.document.title = `${count ? `(${count}条消息) ` : ''}${fullTitle}`
};

export const requestAnimation = (task) => {
  if ('requestAnimationFrame' in window) return window.requestAnimationFrame(task)
  setTimeout(task, 16)
}

export default {
  title
}
