import { render, staticRenderFns } from "./select-approver.vue?vue&type=template&id=38789d3c&scoped=true&"
import script from "./select-approver.vue?vue&type=script&lang=js&"
export * from "./select-approver.vue?vue&type=script&lang=js&"
import style0 from "./select-approver.vue?vue&type=style&index=0&id=38789d3c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38789d3c",
  null
  
)

export default component.exports